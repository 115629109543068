import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=73711068&scoped=true&"
import script from "./Details.vue?vue&type=script&lang=ts&"
export * from "./Details.vue?vue&type=script&lang=ts&"
import style0 from "./Details.vue?vue&type=style&index=0&id=73711068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73711068",
  null
  
)

export default component.exports