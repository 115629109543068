























































































































import { toggleModal } from '@/helpers'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import DocumentDetailsPartial from '@/components/document/Details.vue'
import DocumentDetailNotesPartial from '@/components/document/Notes.vue'

import { DocumentResource } from '@/models/documents/DocumentResource'
import { stateModule } from '@/store'

@Component({
  components: {
    DocumentDetailsPartial,
    DocumentDetailNotesPartial,
  },
})
export default class DocumentViewerModal extends Vue {
  @Prop()
  private detail!: DocumentResource | null

  @Prop()
  private open!: boolean

  @Prop({ default: false })
  private simpleModal!: boolean

  private showRelevantDropdown: boolean = false
  private relevanceStatuses: string[] = ['relevant', 'irrelevant']
  private page: 'details' | 'notes' = 'details'
  private message: string = ''
  private loading: boolean = false

  private get canChangeRelevance(): boolean {
    return this.detail
      ? this.detail.canPerformAction('can_change_relevance')
      : false
  }

  private get canBookmark(): boolean {
    return this.detail ? this.detail.canPerformAction('can_bookmark') : false
  }

  private get relevantButtonClasses(): { [key: string]: boolean } | null {
    if (this.detail) {
      return {
        approve:
          this.detail.relevance === 'relevant' ||
          this.detail.relevance === 'Relevant',
        decline:
          this.detail.relevance === 'irrelevant' ||
          this.detail.relevance === 'NotRelevant' ||
          this.detail.relevance === 'Not Relevant',
        grey:
          this.detail.relevance === 'unmarked' ||
          this.detail.relevance === 'Unmarked',
        open: this.showRelevantDropdown,
      }
    }
    return null
  }

  @Watch('open')
  private onOpenChange(val: boolean): void {
    toggleModal(val)
    if (val) {
      window.addEventListener('keyup', this.checkKey)
    } else {
      window.removeEventListener('keyup', this.checkKey)
    }
  }

  private checkKey(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  private close(): void {
    this.$emit('close')
  }

  private async setStatus(status: string): Promise<void> {
    if (this.detail) {
      stateModule.setLoading(true)
      try {
        await this.detail.patch({ relevance: status })
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async bookmark(): Promise<void> {
    if (this.detail) {
      stateModule.setLoading(true)
      try {
        await this.detail.patch({ bookmarked: !this.detail.bookmarked })
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private async comment(): Promise<void> {
    if (this.detail) {
      stateModule.setLoading(true)
      try {
        await this.detail.postComment({ message: this.message })
      } finally {
        this.message = ''
        stateModule.setLoading(false)
      }
    }
  }

  private download(): void {
    if (!this.detail) {
      return
    }

    window.open(this.detail.link, '_blank')
  }

  private goTo(page: 'details' | 'notes'): void {
    this.page = page
  }

  private toggleRelevant(): void {
    this.showRelevantDropdown = !this.showRelevantDropdown
  }
}
